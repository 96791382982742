<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="20"
    class="elevation-2"
    locale="pt-BR"
    no-data-text="Nenhum dado encontrado"
    :footer-props="{
      'items-per-page-text': 'Itens por página:',
      'items-per-page-options': [20, 50, 100],
      'show-first-last-page': true,
      'show-current-page': true,
    }"
  ></v-data-table>
</template>

<script>
export default {
  name: "RelatorioTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
