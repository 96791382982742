<template>
  <v-expansion-panels v-model="panel" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header> Filtro </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
          <v-row class="px-6 pt-2">
            <v-col cols="3" class="pb-0">
              <v-select
                v-model="formData.exhibition"
                placeholder="Exibição"
                outlined
                dense
                :clearable="true"
                label="Exibição"
                required
                :rules="rules.selectRules"
                :items="exhibitions"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.startDate"
                    placeholder="Data Inicial"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Inicial"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.startDate"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="nowDate"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuStartDate = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-menu
                ref="menuEndData"
                v-model="menuEndData"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.endDate"
                    placeholder="Data Final"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Final"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.endDate"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="nowDate"
                  :min="formData.startDate"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndData = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuEndData = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.employeeId"
                :items="listFuncionarios"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Funcionário"
                label="Funcionário"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.sectorsId"
                :items="listSectors"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Setor"
                label="Setor"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.districtId"
                :items="listDistricts"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Distrito"
                label="Distrito"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.regionalId"
                :items="listRegional"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Regional"
                label="Regional"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.salesForcesId"
                :items="listSaleForce"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Força de Venda"
                label="Força de Venda"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                v-model="formData.roleId"
                :items="listRolePermission"
                hide-selected
                item-text="label"
                item-value="id"
                placeholder="Cargo"
                label="Cargo"
                outlined
                dense
                :clearable="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-select
                v-model="formData.field"
                placeholder="Campos"
                outlined
                dense
                :clearable="true"
                label="Campos"
                :items="fields"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="formData.value"
                placeholder="Valor"
                outlined
                dense
                :clearable="true"
                label="Valor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn
                depressed
                color="green accent-4"
                dark
                @click="
                  formData.download = true;
                  send();
                "
                :loading="loadingBtn"
                block
                >EXPORTAR XLS</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                depressed
                color="fmq_gray"
                dark
                @click="
                  formData.download = false;
                  send();
                "
                :disabled="loadingBtn"
                block
                >Gerar Tabela</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "RelatorioTermoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    listProducts: {
      type: Array,
      default: () => [],
    },
    listFuncionarios: {
      type: Array,
      default: () => [],
    },
    listSectors: {
      type: Array,
      default: () => [],
    },
    listDistricts: {
      type: Array,
      default: () => [],
    },
    listRegional: {
      type: Array,
      default: () => [],
    },
    listSaleForce: {
      type: Array,
      default: () => [],
    },
    listRolePermission: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      exhibition: null,
      startDate: null,
      endDate: null,
      productId: null,
      employeeId: null,
      sectorId: null,
      districtId: null,
      regionalId: null,
      salesForcesId: null,
      roleId: null,
      download: false,
      field: null,
      value: null,
    },
    exhibitions: [
      {
        label: "Horas",
        value: "hour",
      },
      {
        label: "Dia",
        value: "day",
      },
      {
        label: "Mês",
        value: "month",
      },
      {
        label: "Ano",
        value: "year",
      },
    ],
    fields: [
      {
        label: "Busca",
        value: "search",
      },
      {
        label: "Nome",
        value: "name",
      },
      {
        label: "Tipo",
        value: "type",
      },
      {
        label: "Linha",
        value: "line",
      },
      {
        label: "Laboratório",
        value: "laboratory",
      },
      {
        label: "Composição",
        value: "composition",
      },
      {
        label: "Classe Terapêutica",
        value: "therapeuticClass",
      },
      {
        label: "Classificação",
        value: "classification",
      },
      {
        label: "Patologias",
        value: "pathologies",
      },
      {
        label: "Apresentações",
        value: "presentations",
      },
      {
        label: "Idicação",
        value: "indication",
      },
      {
        label: "Dosagem",
        value: "dosage",
      },
      {
        label: "Perfil do paciente",
        value: "patientProfile",
      },
      {
        label: "Concorrentes Diretos",
        value: "directCompetitors",
      },
      {
        label: "Concorrentes Indiretos",
        value: "indirectCompetitors",
      },
      {
        label: "Especialidades",
        value: "specialties",
      },
      {
        label: "Foçra de Venda",
        value: "salesForces",
      },
    ],
    rules: rules,
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    menuStartDate: false,
    menuEndData: false,
    panel: [0]
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) {
        this.formData.download ? (this.panel = [0]) : (this.panel = []);
        this.$emit("send", this.formData);
      }
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>
